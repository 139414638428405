import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { X, Check } from 'lucide-react';
import '../styles/modalrequests.css';

const ModalRequest = ({ paquete, onClose, onUpdate }) => {
    const [estados, setEstados] = useState([]);
    const [selectedMovimientos, setSelectedMovimientos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
    const environment = process.env.NODE_ENV || 'development';

    useEffect(() => {
        const fetchEstados = async () => {
            try {
                const userId = localStorage.getItem('userId');
                const response = await axios.get(
                    `${config[environment].API_BASE_URL}get_estados.php`,
                    { params: { userId } }
                );
                setEstados(response.data);
            } catch (err) {
                setError('Error al cargar los estados disponibles');
            }
        };

        fetchEstados();
    }, [environment, error]);

    const handleCheckboxChange = (idMovimiento) => {
        setSelectedMovimientos(prev => 
            prev.includes(idMovimiento)
                ? prev.filter(id => id !== idMovimiento)
                : [...prev, idMovimiento]
        );
    };

    const handleSelectAll = () => {
        setSelectedMovimientos(prev => 
            prev.length === paquete.movimientos.length
                ? []
                : paquete.movimientos.map(m => m.idMovimiento)
        );
    };

    const updateEstado = async (idMovimiento, idEstado) => {
        try {
            setLoading(true);
            const userId = localStorage.getItem('userId');
            
            await axios.post(`${config[environment].API_BASE_URL}update_movimiento_estado.php`, {
                userId,
                idMovimiento,
                idEstado
            });

            const updatedPaquete = {
                ...paquete,
                movimientos: paquete.movimientos.map(mov => 
                    mov.idMovimiento === idMovimiento 
                        ? { ...mov, idEstado: parseInt(idEstado) }
                        : mov
                )
            };
            onUpdate(updatedPaquete);
            setError(null);
        } catch (err) {
            setError('Error al actualizar el estado');
        } finally {
            setLoading(false);
        }
    };

    const CustomCheckbox = ({ checked, onChange }) => (
        <div className="checkbox-wrapper" onClick={onChange}>
            <div className={`custom-checkbox ${checked ? 'checked' : ''}`}>
                {checked && <Check size={14} color="white" />}
            </div>
        </div>
    );

    return (
        <div className="modal-requests-overlay">
            <div className="modal-requests-container">
                <div className="modal-requests-header">
                    <h2 className="modal-requests-title">Detalles del Paquete #{paquete.idPaquete}</h2>
                    <button onClick={onClose} className="modal-requests-close">
                        <X size={24} />
                    </button>
                </div>

                <div className="modal-requests-content">
                    <table className="movements-table">
                        <thead>
                            <tr>
                                <th style={{width: '40px'}}>
                                    <CustomCheckbox 
                                        checked={selectedMovimientos.length === paquete.movimientos.length}
                                        onChange={handleSelectAll}
                                    />
                                </th>
                                <th>ID</th>
                                <th>Artículo</th>
                                <th>Unidades</th>
                                <th>Estado Actual</th>
                                <th>Cambiar Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paquete.movimientos.map((movimiento) => (
                                <tr key={movimiento.idMovimiento}>
                                    <td>
                                        <CustomCheckbox 
                                            checked={selectedMovimientos.includes(movimiento.idMovimiento)}
                                            onChange={() => handleCheckboxChange(movimiento.idMovimiento)}
                                        />
                                    </td>
                                    <td>#{movimiento.idMovimiento}</td>
                                    <td>{movimiento.idArticulo}</td>
                                    <td>{movimiento.iUnidades}</td>
                                    <td>{estados.find(e => e.idEstado === movimiento.idEstado)?.sEstado || 'Cargando...'}</td>
                                    <td>
                                        <select
                                            className="estado-select"
                                            value={movimiento.idEstado}
                                            onChange={(e) => updateEstado(movimiento.idMovimiento, e.target.value)}
                                            disabled={loading}
                                        >
                                            {estados.map(estado => (
                                                <option key={estado.idEstado} value={estado.idEstado}>
                                                    {estado.sEstado}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="modal-requests-footer">
                    <div className="total-movements">
                        {paquete.movimientos.length} movimientos en total
                    </div>
                    {/* <button onClick={onClose} className="close-modal-requests-button">
                        Cerrar
                    </button> */}
                </div>
            </div>
        </div>
    );
};

export default ModalRequest;