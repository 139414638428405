import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import '../styles/crono.css';

const StartButton = ({ onClick }) => (
  <button 
    className="chrono-button start-button"
    onClick={onClick}
    aria-label="Iniciar marcaje"
  >
    <svg 
      viewBox="0 0 24 24" 
      fill="currentColor"
      width="128"
      height="128"
    >
      <path d="M8 5v14l11-7z" />
    </svg>
  </button>
);

const StopButton = ({ onClick }) => (
  <button 
    className="chrono-button stop-button"
    onClick={onClick}
    aria-label="Detener marcaje"
  >
    <svg 
      viewBox="0 0 24 24" 
      fill="currentColor"
      width="128"
      height="128"
    >
      <rect x="6" y="6" width="12" height="12" />
    </svg>
  </button>
);

const ChronoButton = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [marcajeId, setMarcajeId] = useState(null);
  const [error, setError] = useState(null);

  const environment = process.env.NODE_ENV || 'development';
  const apiUrl = config[environment].API_BASE_URL;

  const checkMarcajeStatus = useCallback(async (userId) => {
    try {
      const params = new URLSearchParams();
      params.append('userId', userId);

      const response = await axios.get(`${apiUrl}check_marcaje.php?${params.toString()}`);
      
      if (response.data.success) {
        if (response.data.isClosed) {
          setIsRunning(false);
          setMarcajeId(null);
          localStorage.removeItem('marcajeId');
        } else {
          setIsRunning(true);
          setMarcajeId(response.data.marcajeId);
          localStorage.setItem('marcajeId', response.data.marcajeId);
        }
      }
      return response.data;
    } catch (error) {
      console.error('Error al verificar el estado del marcaje:', error);
      throw error;
    }
  }, [apiUrl]);

  const startChrono = async () => {
    try {
      setError(null);
      const userId = localStorage.getItem('userId');
      
      if (!userId) {
        throw new Error('No se encontró la información del usuario');
      }

      const clientId = localStorage.getItem('selectedClientId');
      
      if (!clientId) {
        throw new Error('No se ha seleccionado ningún cliente');
      }

      const checkResult = await checkMarcajeStatus(userId);
      if (!checkResult.isClosed) {
        throw new Error('Ya existe un marcaje abierto');
      }

      const currentDate = new Date();
      const horaIni = currentDate.toLocaleTimeString('es-ES', { 
        hour: '2-digit', 
        minute: '2-digit',
        second: '2-digit',
        hour12: false 
      });
      const dFecha = currentDate.toLocaleDateString('es-ES', { 
        day: '2-digit', 
        month: '2-digit', 
        year: 'numeric' 
      });

      const payload = {
        idUsuario: parseInt(userId),
        idCliente: parseInt(clientId),
        HoraIni: horaIni,
        dFecha: dFecha
      };

      const response = await axios.post(`${apiUrl}start_marcaje.php`, payload);

      if (response.data.success) {
        const newMarcajeId = response.data.marcajeId;
        setMarcajeId(newMarcajeId);
        setIsRunning(true);
        localStorage.setItem('marcajeId', newMarcajeId);
      } else {
        throw new Error(response.data.error || 'Error al iniciar el marcaje');
      }
    } catch (error) {
      console.error('Error detallado al iniciar marcaje:', error);
      const errorMessage = error.response?.data?.error || error.message || 'Error al iniciar el marcaje';
      setError(errorMessage);
      setIsRunning(false);
    }
  };

  const stopChrono = async () => {
    try {
      setError(null);
      const userId = localStorage.getItem('userId');
      
      if (!userId) {
        throw new Error('No se encontró la información del usuario');
      }

      const checkResult = await checkMarcajeStatus(userId);
      if (checkResult.isClosed) {
        throw new Error('No hay ningún marcaje abierto');
      }

      const endTime = new Date();
      const horaFin = endTime.toLocaleTimeString('es-ES', { 
        hour: '2-digit', 
        minute: '2-digit',
        second: '2-digit',
        hour12: false 
      });

      const horaIniParts = checkResult.horaIni.split(':');
      const fechaParts = checkResult.fecha.split('-');
      const startTime = new Date(
        parseInt(fechaParts[0]), // año
        parseInt(fechaParts[1]) - 1, // mes 
        parseInt(fechaParts[2]), // dia
        parseInt(horaIniParts[0]), // hora
        parseInt(horaIniParts[1]), // minutos
        parseInt(horaIniParts[2] || 0) // segundos
      );

      const durationInSeconds = Math.floor((endTime - startTime) / 1000);
      const hours = Math.floor(durationInSeconds / 3600);
      const minutes = Math.floor((durationInSeconds % 3600) / 60);
      const seconds = durationInSeconds % 60;
      const duration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  
      const payload = {
        idMarcaje: parseInt(checkResult.marcajeId),
        HoraFin: horaFin,
        sDuracion: duration
      };
  
      const response = await axios.post(`${apiUrl}stop_marcaje.php?userId=${userId}`, payload);
  
      if (response.data.success) {
        setIsRunning(false);
        setMarcajeId(null);
        localStorage.removeItem('marcajeId');
      } else {
        throw new Error(response.data.error || 'Error al detener el marcaje');
      }
    } catch (error) {
      console.error('Error detallado al detener marcaje:', error);
      const errorMessage = error.response?.data?.error || error.message || 'Error al detener el marcaje';
      setError(errorMessage);
    }
  };

  const handleClick = async () => {
    try {
      if (isRunning) {
        await stopChrono();
      } else {
        await startChrono();
      }
    } catch (error) {
      console.error('Error en handleClick:', error);
      setError('Error al procesar la operación');
    }
  };

  useEffect(() => {
    const checkInitialStatus = async () => {
      const userId = localStorage.getItem('userId');
      
      if (userId) {
        try {
          await checkMarcajeStatus(userId);
        } catch (error) {
          console.error('Error al verificar el estado inicial:', error);
          setError('Error al verificar el estado del marcaje');
        }
      }
    };

    checkInitialStatus();
  }, [checkMarcajeStatus, marcajeId]);

  return (
    <div className="chrono-container">
      <div className="chrono-button-wrapper">
        {isRunning ? (
          <StopButton onClick={handleClick} />
        ) : (
          <StartButton onClick={handleClick} />
        )}
      </div>
      {error && (
        <div className="error-message">
          <p>{error}</p>
          <button 
            className="error-close"
            onClick={() => setError(null)}
          >
            ×
          </button>
        </div>
      )}
    </div>
  );
};

export default ChronoButton;