import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import '../styles/loggedas.css';
import { useAuth } from '../AuthContext';

const LoggedAs = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      const userId = localStorage.getItem('userId');
      const storedUsername = localStorage.getItem('sUsuario');
      
      if (!userId || !isAuthenticated) {
        setError('No se ha encontrado el ID del usuario');
        return;
      }

      if (storedUsername) {
        setUserData({ sUsuario: storedUsername });
        return;
      }

      try {
        const response = await axios.get(`${config[process.env.NODE_ENV || 'development'].API_BASE_URL}/get_user.php`, {
          params: { userId }
        });

        if (response.data && response.data.length > 0) {
          setUserData(response.data[0]);
          localStorage.setItem('sUsuario', response.data[0].sUsuario);
        } else {
          setError('No se encontraron datos del usuario');
        }
      } catch (err) {
        setError('Error al cargar los datos del usuario');
        console.error('Error:', err);
      }
    };

    if (isAuthenticated) {
      fetchUserData();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return null;
  }

  if (error) {
    return (
      <div className="logged-as-container error">
        <span className="logged-as-text">Error: {error}</span>
      </div>
    );
  }

  return (
    <div className="logged-as-container">
      <span className="logged-as-text">
        {userData ? `Sesión: ${userData.sUsuario}` : 'Cargando...'}
      </span>
    </div>
  );
};

export default LoggedAs;