import React, { useState, useEffect } from 'react';
import { Sun, Moon } from 'lucide-react';
import '../styles/theme.css';

const ThemeSwitcher = () => {
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    console.log(savedTheme)
    
    if (savedTheme) {
      return savedTheme;
    }
    
    if (window.matchMedia) {
      const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      return darkModeMediaQuery.matches ? 'dark' : 'light';
    }
    
    return 'light';
  });

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  useEffect(() => {
    if (!localStorage.getItem('theme') && window.matchMedia) {
      const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      
      const handleThemeChange = (e) => {
        setTheme(e.matches ? 'dark' : 'light');
      };

      darkModeMediaQuery.addEventListener('change', handleThemeChange);

      return () => {
        darkModeMediaQuery.removeEventListener('change', handleThemeChange);
      };
    }
  }, []);

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  };

  return (
    <button 
      onClick={toggleTheme}
      className="theme-switcher"
      aria-label="Cambiar tema"
      title={theme === 'light' ? 'Cambiar a modo oscuro' : 'Cambiar a modo claro'}
    >
      <div className={`icon-container ${theme}`}>
        {theme === 'light' ? (
          <Moon className="theme-icon" />
        ) : (
          <Sun className="theme-icon" />
        )}
      </div>
    </button>
  );
};

export default ThemeSwitcher;