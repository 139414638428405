import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import '../styles/modal.css';
import { useAuth } from '../AuthContext';

const ModalPermissions = ({ isOpen, onClose, user }) => {
  const [routes, setRoutes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { logout } = useAuth();

  const fetchRoutes = useCallback(async () => {
    if (!user?.idUsuario) return;
    
    setLoading(true);
    try {
      const environment = process.env.NODE_ENV || 'development';
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${config[environment].PHP_GET_ROUTES_URL}`, {
        params: { userId: user.idUsuario },
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.data.success) {
        setRoutes(response.data.data);
      } else {
        throw new Error(response.data.message || 'Error al obtener los datos');
      }
    } catch (error) {
      console.error('Error fetching routes:', error);
      if (error.response?.status === 401) {
        setError('Sesión expirada. Por favor, inicie sesión de nuevo.');
        logout();
      } else {
        setError('No se pudieron obtener los permisos. Por favor, inténtelo de nuevo más tarde.');
      }
    } finally {
      setLoading(false);
    }
  }, [user, logout]);

  useEffect(() => {
    if (isOpen && user) {
      fetchRoutes();
    }
  }, [isOpen, user, fetchRoutes]);

  const handlePermissionChange = async (sRuta, permissionType, checked) => {
    if (!user?.idUsuario) return;
    
    setSaving(true);
    try {
      const environment = process.env.NODE_ENV || 'development';
      const token = localStorage.getItem('authToken');
      const route = routes.find(r => r.sRuta === sRuta);
      
      const updatedPermissions = {
        ...route.permisos,
        [permissionType]: checked ? 1 : 0
      };

      const response = await axios.post(
        `${config[environment].PHP_UPDATE_PERMISSIONS_URL}`,
        {
          userId: user.idUsuario,
          sRuta,
          permisos: updatedPermissions
        },
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      if (response.data.success) {
        setRoutes(routes.map(route => 
          route.sRuta === sRuta 
            ? { ...route, permisos: updatedPermissions }
            : route
        ));
      } else {
        throw new Error(response.data.message || 'Error al actualizar los permisos');
      }
    } catch (error) {
      console.error('Error updating permissions:', error);
      setError('No se pudieron actualizar los permisos. Por favor, inténtelo de nuevo más tarde.');
    } finally {
      setSaving(false);
    }
  };

  if (!isOpen || !user) return null;
  if (loading) return (
    <div className="modal-overlay-permissions">
      <div className="modal-content-permissions">
        <p>Cargando permisos...</p>
      </div>
    </div>
  );

  return (
    <div className="modal-overlay-permissions">
      <div className="modal-content-permissions">
        <div className="modal-header-permissions">
          <h2>Permisos de {user.sNombre_Completo}</h2>
          <button 
            className="modal-close-permissions" 
            onClick={onClose}
            disabled={saving}
          >
            &times;
          </button>
        </div>
        
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}

        <div className="table-permissions">
          <div className="table-header-permissions">
            <div className="column-route">Pantalla</div>
            <div className="column-permissions">
              <div className="permission-label">Leer</div>
              <div className="permission-label">Crear</div>
              <div className="permission-label">Modificar</div>
              <div className="permission-label">Borrar</div>
              <div className="permission-label">Importar</div>
            </div>
          </div>

          <div className="table-body-permissions">
            {routes.map((route) => (
              <div key={route.sRuta} className="table-row-permissions">
                <div className="column-route">{route.sRuta}</div>
                <div className="column-permissions">
                  <div className="permission-checkbox">
                    <input
                      type="checkbox"
                      checked={route.permisos.leer}
                      onChange={(e) => handlePermissionChange(route.sRuta, 'leer', e.target.checked)}
                      disabled={saving}
                    />
                  </div>
                  <div className="permission-checkbox">
                    <input
                      type="checkbox"
                      checked={route.permisos.crear}
                      onChange={(e) => handlePermissionChange(route.sRuta, 'crear', e.target.checked)}
                      disabled={saving}
                    />
                  </div>
                  <div className="permission-checkbox">
                    <input
                      type="checkbox"
                      checked={route.permisos.modificar}
                      onChange={(e) => handlePermissionChange(route.sRuta, 'modificar', e.target.checked)}
                      disabled={saving}
                    />
                  </div>
                  <div className="permission-checkbox">
                    <input
                      type="checkbox"
                      checked={route.permisos.borrar}
                      onChange={(e) => handlePermissionChange(route.sRuta, 'borrar', e.target.checked)}
                      disabled={saving}
                    />
                  </div>
                  <div className="permission-checkbox">
                    <input
                      type="checkbox"
                      checked={route.permisos.importar}
                      onChange={(e) => handlePermissionChange(route.sRuta, 'importar', e.target.checked)}
                      disabled={saving}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {saving && (
          <div className="saving-message">
            Guardando cambios...
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalPermissions;