import React from 'react';
import "../styles/header.css";
import Logout from './LogOut';
import ThemeSwitcher from './ThemeSwitcher';
import LoggedAs from './LoggedAs';

const Header = () => {
    return (
        <nav className='nav-header'>
            <div className='lg'>
                <ThemeSwitcher />
            </div>
            <div className="nh">
                <a href="/" className="t1">WorkSuite</a>
            </div>
            <div className="nt">
                <LoggedAs />
                <Logout />
            </div>
        </nav>
    )
}

export default Header;