import React, { useState } from 'react';
import axios from 'axios';
import { X } from 'lucide-react';
import config from '../config';
import '../styles/editLocations.css';

const EditLocations = ({ location, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    nombre: location.nombre,
    direccion: location.direccion,
    poblacion: location.poblacion,
    provincia: location.provincia,
    cp: location.cp,
    coordenadas: location.coordenadas
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const environment = process.env.NODE_ENV || 'development';
      const userId = localStorage.getItem('userId');
      const response = await axios.post(`${config[environment].API_BASE_URL}/update_location.php`, {
        ...formData,
        id: location.id,
        userId
      });

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      onSave({
        ...location,
        ...formData
      });
    } catch (err) {
      setError('Error al actualizar la ubicación');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="edit-location-modal">
      <div className="edit-location-content">
        <button className="close-button" onClick={onClose}>
          <X size={24} />
        </button>
        
        <h2>Editar Ubicación</h2>
        
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="nombre">Nombre</label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="direccion">Dirección</label>
            <input
              type="text"
              id="direccion"
              name="direccion"
              value={formData.direccion}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="poblacion">Población</label>
              <input
                type="text"
                id="poblacion"
                name="poblacion"
                value={formData.poblacion}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="provincia">Provincia</label>
              <input
                type="text"
                id="provincia"
                name="provincia"
                value={formData.provincia}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="cp">Código Postal</label>
              <input
                type="text"
                id="cp"
                name="cp"
                value={formData.cp}
                onChange={handleChange}
                required
                maxLength="5"
                pattern="[0-9]{5}"
              />
            </div>

            <div className="form-group">
              <label htmlFor="coordenadas">Coordenadas</label>
              <input
                type="text"
                id="coordenadas"
                name="coordenadas"
                value={formData.coordenadas}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {error && <div className="error-message">{error}</div>}

          <div className="button-group">
            <button type="button" onClick={onClose} className="cancel-button">
              Cancelar
            </button>
            <button type="submit" className="save-button" disabled={loading}>
              {loading ? 'Guardando...' : 'Guardar Cambios'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditLocations;