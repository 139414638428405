import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { Circles } from 'react-loader-spinner';

const RelationsInfoComponent = ({ id, tipo }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${config.production.API_BASE_URL}get_relations.php`, {
          params: { id, tipo }
        });
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error al cargar los datos. Por favor, inténtelo de nuevo más tarde.');
        setLoading(false);
      }
    };

    fetchData();
  }, [id, tipo]);

  if (loading) return (
    <div style={{ width: "100%", height: "90vh", display: "flex", justifyContent: "center", alignContent: "center", flexWrap: "wrap" }}>
      <Circles
        height="80"
        width="80"
        color="#000000"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
  
  if (error) return <div>{error}</div>;

  const renderItem = (item) => (
    <li key={tipo === 'usuario' ? item.idCliente : item.idUsuario}>
      {tipo === 'usuario' ? (
        <>
          <strong>Cliente:</strong> {item.sCliente}<br />
          <strong>ID:</strong> {item.idCliente}<br />
          <strong>Estado:</strong> {item.bActivo ? 'Activo' : 'Inactivo'}
        </>
      ) : (
        <>
          <strong>Usuario:</strong> {item.sUsuario}<br />
          <strong>ID:</strong> {item.idUsuario}<br />
          <strong>Estado:</strong> {item.bActivo ? 'Activo' : 'Inactivo'}
        </>
      )}
    </li>
  );

  return (
    <div>
      <h2>{tipo === 'usuario' ? 'Información del Cliente' : 'Información del Usuario'}</h2>
      {data ? (
        Array.isArray(data) ? (
          <ul>
            {data.map(renderItem)}
          </ul>
        ) : (
          <ul>
            {renderItem(data)}
          </ul>
        )
      ) : (
        <p>No se encontraron datos.</p>
      )}
    </div>
  );
};

export default RelationsInfoComponent;