import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';
import axios from 'axios';
import config from '../config';
import '../styles/pdfgenerator.css';

// Función auxiliar para convertir colores hexadecimales a RGB
const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : [0, 0, 0];
};

const PDFGenerator = ({
    selectedMarcajes,
    currentMarcajes,
    showClientInfo,
    ignoreDateFilter,
    startDate,
    endDate,
    totalDuration
}) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);

    const generatePDFDocument = async () => {
        try {
            const empresaId = localStorage.getItem('empresaId');

            if (!empresaId) {
                throw new Error('No se encontró el ID de la empresa');
            }

            const environment = process.env.NODE_ENV || 'development';
            const response = await axios.get(`${config[environment].API_BASE_URL}get_pdf_config.php`, {
                params: { empresaId }
            });

            if (!response.data.success) {
                throw new Error(response.data.message);
            }

            const pdfConfig = response.data.config;

            const doc = new jsPDF({
                orientation: pdfConfig.page.orientation,
                unit: 'mm',
                format: pdfConfig.page.format
            });

            const selectedRows = currentMarcajes.filter(marcaje => selectedMarcajes[marcaje.idMarcaje]);

            if (selectedRows.length === 0) {
                throw new Error("Por favor, seleccione al menos una fila para generar el PDF");
            }

            doc.setFont(pdfConfig.header.company_name.style.font);

            const logoConfig = pdfConfig.header.logo;
            doc.setFillColor(...hexToRgb(logoConfig.style.background));
            doc.setDrawColor(...hexToRgb(logoConfig.style.border));
            doc.rect(
                logoConfig.position.x,
                logoConfig.position.y,
                logoConfig.size.width,
                logoConfig.size.height,
                'FD'
            );
            doc.setFontSize(10);
            doc.text('[LOGO]', logoConfig.position.x + 6, logoConfig.position.y + 13);

            const companyNameConfig = pdfConfig.header.company_name;
            doc.setFontSize(companyNameConfig.style.size);
            doc.setFont(companyNameConfig.style.font, companyNameConfig.style.weight);
            doc.text(
                companyNameConfig.text,
                companyNameConfig.position.x,
                companyNameConfig.position.y
            );

            const addressConfig = pdfConfig.header.company_address;
            doc.setFontSize(addressConfig.style.size);
            doc.setFont(addressConfig.style.font, "normal");
            doc.text(
                pdfConfig.metadata.address,
                addressConfig.position.x,
                addressConfig.position.y
            );

            doc.setDrawColor(226, 232, 240);
            doc.line(15, 42, 195, 42);

            doc.setFontSize(16);
            doc.setFont("helvetica", "bold");
            const operario = selectedRows[0].sNombre_Completo;
            doc.text(`Informe de Marcajes - ${operario}`, 15, 55);

            doc.setFontSize(14);
            doc.setFont("helvetica", "normal");
            const periodoText = ignoreDateFilter
                ? "Período: Todas las fechas"
                : `Período: ${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}`;
            doc.text(periodoText, 15, 63);

            const tableHeaders = [['Fecha', 'Hora Inicio', 'Hora Fin', 'Duración']];
            const tableData = selectedRows.map(marcaje => [
                format(new Date(marcaje.dFecha), 'dd/MM/yyyy'),
                marcaje.HoraIni,
                marcaje.HoraFin || '-',
                marcaje.sDuracion || '-'
            ]);

            doc.autoTable({
                head: tableHeaders,
                body: tableData,
                startY: 70,
                styles: {
                    fontSize: 11,
                    cellPadding: 3
                },
                headStyles: {
                    fillColor: [51, 51, 51],
                    textColor: [255, 255, 255],
                    fontSize: 12,
                    fontStyle: 'bold'
                },
                columnStyles: {
                    0: { cellWidth: 40 },
                    1: { cellWidth: 40 },
                    2: { cellWidth: 40 },
                    3: { cellWidth: 30 }
                },
                alternateRowStyles: {
                    fillColor: [248, 249, 250]
                },
                margin: { left: 15, right: 15 }
            });

            const finalY = doc.previousAutoTable.finalY || 40;
            doc.setFillColor(248, 249, 250);
            doc.rect(15, finalY + 5, 180, 15, 'F');
            doc.setFont("helvetica", "bold");
            doc.setFontSize(14);
            doc.text("Total horas:", 140, finalY + 15);
            doc.text(totalDuration, 170, finalY + 15);

            doc.setDrawColor(226, 232, 240);
            doc.line(15, 280, 195, 280);
            doc.setFontSize(10);
            doc.setFont("helvetica", "normal");

            const pageCount = doc.internal.getNumberOfPages();
            for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                doc.text(
                    `Página ${i} de ${pageCount}`,
                    15,
                    287
                );
                doc.text(
                    `Generado el ${format(new Date(), 'dd/MM/yyyy HH:mm')}`,
                    140,
                    287
                );
            }

            return doc;
        } catch (err) {
            setError(err.message || 'Error al generar el PDF');
            return null;
        }
    };

    const handlePreview = async () => {
        setLoading(true);
        setError('');
        const doc = await generatePDFDocument();
        if (doc) {
            const pdfBlob = doc.output('bloburl');
            setPdfUrl(pdfBlob);
            setShowPreview(true);
        }
        setLoading(false);
    };

    const handleDownload = async () => {
        setLoading(true);
        setError('');
        const doc = await generatePDFDocument();
        if (doc) {
            doc.save(`Marcajes_${format(new Date(), 'yyyyMMdd_HHmm')}.pdf`);
        }
        setLoading(false);
    };

    const handleClosePreview = () => {
        setShowPreview(false);
        if (pdfUrl) {
            URL.revokeObjectURL(pdfUrl);
            setPdfUrl(null);
        }
    };

    const selectedCount = Object.values(selectedMarcajes).filter(Boolean).length;

    return (
        <>
            <div className="pdf-generator-buttons">
                <button
                    onClick={handlePreview}
                    disabled={loading || selectedCount === 0}
                    className={`pdf-generator-button preview ${loading || selectedCount === 0 ? 'disabled' : ''}`}
                >
                    Vista Previa ({selectedCount} seleccionados)
                </button>
                <button
                    onClick={handleDownload}
                    disabled={loading || selectedCount === 0}
                    className={`pdf-generator-button download ${loading || selectedCount === 0 ? 'disabled' : ''}`}
                >
                    Descargar PDF
                </button>
            </div>

            {error && (
                <div className="pdf-error-message">
                    <span className="pdf-error-icon">!</span>
                    <div className="pdf-error-content">
                        <h3>Error</h3>
                        <p>{error}</p>
                    </div>
                </div>
            )}

            {showPreview && (
                <div className="pdf-preview-modal">
                    <div className="pdf-preview-content">
                        <div className="pdf-preview-header">
                            <h2>Vista Previa del PDF</h2>
                            <button
                                onClick={handleClosePreview}
                                className="pdf-preview-close"
                            >
                                ×
                            </button>
                        </div>
                        <div className="pdf-preview-body">
                            <iframe
                                src={pdfUrl}
                                title="Vista previa del PDF"
                            />
                        </div>
                        <div className="pdf-preview-footer">
                            <button
                                onClick={handleDownload}
                                className="pdf-generator-button download"
                            >
                                Descargar PDF
                            </button>
                            <button
                                onClick={handleClosePreview}
                                className="pdf-generator-button cancel"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PDFGenerator;