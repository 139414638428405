import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import "../styles/modal.css";
import { useAuth } from "../AuthContext";

const ModalStaff = ({ isOpen, onClose, user, onOpenPermissions }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const { logout } = useAuth();

  useEffect(() => {
    if (!isOpen) {
      setIsEditing(false);
      setError(null);
      setSaving(false);
    }
    if (user) {
      setFormData({
        idUsuario: user.idUsuario || "",
        sUsuario: user.sUsuario || "",
        sNombre_Completo: user.sNombre_Completo || "",
        sEmail: user.sEmail || "",
        bEstado: user.bEstado || 0,
        TCategoria: user.TCategoria || 1
      });
    }
  }, [isOpen, user]);

  const fetchCategories = async () => {
    try {
      const environment = process.env.NODE_ENV || "development";
      const token = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      const response = await axios.get(
        `${config[environment].PHP_GET_CATEGORIES_URL}`,
        {
          params: { userId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.success) {
        setCategories(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    if (isEditing) {
      fetchCategories();
    }
  }, [isEditing]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let processedValue = value;

    if (name === "bEstado" || name === "TCategoria") {
      processedValue = parseInt(value) || 0;
    }

    setFormData((prev) => ({
      ...prev,
      [name]: processedValue,
    }));
  };

  const handleClose = () => {
    setIsEditing(false);
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setError(null);

    try {
      const environment = process.env.NODE_ENV || "development";
      const token = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      if (!userId) {
        throw new Error("No se encontró el ID de usuario");
      }

      const response = await axios.post(
        `${config[environment].PHP_UPDATE_USER_URL}`,
        {
          userId: parseInt(userId),
          userToUpdate: {
            ...formData,
            idUsuario: parseInt(formData.idUsuario)
          },
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.success) {
        setIsEditing(false);
        handleClose();
      } else {
        throw new Error(
          response.data.message || "Error al actualizar los datos"
        );
      }
    } catch (error) {
      console.error("Error updating user:", error);
      if (error.response?.status === 401) {
        setError("Sesión expirada. Por favor, inicie sesión de nuevo.");
        logout();
      } else if (error.response?.status === 403) {
        setError("No tiene permisos para realizar esta acción.");
      } else {
        setError(
          "No se pudieron actualizar los datos. Por favor, inténtelo de nuevo más tarde."
        );
      }
    } finally {
      setSaving(false);
    }
  };

  if (!isOpen || !user || !formData) return null;

  return (
    <div className="modal-overlay-staff">
      <div className="modal-content-staff">
        <div className="modal-header-staff">
          <h2>{isEditing ? "Editar Operario" : user.sNombre_Completo}</h2>
          <button
            className="modal-close-staff"
            onClick={handleClose}
            disabled={saving}
          >
            &times;
          </button>
        </div>

        {error && <div className="error-message">{error}</div>}

        {isEditing ? (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>ID Usuario:</label>
              <input
                type="text"
                value={formData.idUsuario}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label>Usuario:</label>
              <input
                type="text"
                name="sUsuario"
                value={formData.sUsuario}
                onChange={handleInputChange}
                disabled={saving}
              />
            </div>

            <div className="form-group">
              <label>Nombre Completo:</label>
              <input
                type="text"
                name="sNombre_Completo"
                value={formData.sNombre_Completo}
                onChange={handleInputChange}
                disabled={saving}
              />
            </div>

            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                name="sEmail"
                value={formData.sEmail}
                onChange={handleInputChange}
                disabled={saving}
              />
            </div>

            <div className="form-group">
              <label>Categoría:</label>
              <select
                name="TCategoria"
                value={formData.TCategoria}
                onChange={handleInputChange}
                disabled={saving}
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.nombre}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Estado:</label>
              <select
                name="bEstado"
                value={formData.bEstado}
                onChange={handleInputChange}
                disabled={saving}
              >
                <option value={0}>Inactivo</option>
                <option value={1}>Activo</option>
              </select>
            </div>

            <div className="form-actions">
              <button type="submit" className="btn-save" disabled={saving}>
                {saving ? "Guardando..." : "Guardar"}
              </button>
              <button
                type="button"
                className="btn-cancel"
                onClick={() => setIsEditing(false)}
                disabled={saving}
              >
                Cancelar
              </button>
            </div>
          </form>
        ) : (
          <div className="user-info">
            <p>
              <strong>ID Usuario:</strong> {formData.idUsuario}
            </p>
            <p>
              <strong>Usuario:</strong> {formData.sUsuario}
            </p>
            <p>
              <strong>Nombre Completo:</strong> {formData.sNombre_Completo}
            </p>
            <p>
              <strong>Email:</strong> {formData.sEmail}
            </p>
            <p>
              <strong>Estado:</strong>{" "}
              {formData.bEstado ? "Activo" : "Inactivo"}
            </p>

            <div className="modal-actions">
              <button
                className="btn-edit"
                onClick={() => setIsEditing(true)}
                disabled={saving}
              >
                Editar Operario
              </button>
              <button
                className="btn-permissions"
                onClick={() => onOpenPermissions(user)}
                disabled={saving}
              >
                Editar Permisos
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalStaff;