import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MapPin, Edit, Trash2 } from 'lucide-react';
import { Circles } from 'react-loader-spinner';
import config from '../config';
import '../styles/locations.css';
import EditLocations from './EditLocations';

const LocationsModal = ({ isOpen, onClose, clientId, clientName }) => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingLocation, setEditingLocation] = useState(null);

  useEffect(() => {
    const fetchLocations = async () => {
      if (!isOpen) return;
      try {
        setLoading(true);
        const environment = process.env.NODE_ENV || 'development';
        const userId = localStorage.getItem('userId');
        const response = await axios.get(`${config[environment].API_BASE_URL}/get_locations.php`, {
          params: { idCliente: clientId, userId: userId }
        });
        const locationsData = Array.isArray(response.data) ? response.data : [];
        setLocations(locationsData);
        setError(null);
      } catch (err) {
        console.error('Error fetching locations:', err);
        setError('Error al cargar las ubicaciones. Por favor, inténtelo de nuevo más tarde.');
        setLocations([]);
      } finally {
        setLoading(false);
      }
    };

    fetchLocations();
  }, [clientId, isOpen]);

  const handleEdit = (location) => {
    setEditingLocation(location);
  };

  const handleDelete = async (locationId) => {
    if (window.confirm('¿Está seguro de que desea eliminar esta ubicación?')) {
      try {
        const environment = process.env.NODE_ENV || 'development';
        const userId = localStorage.getItem('userId');
        await axios.post(`${config[environment].API_BASE_URL}/delete_location.php`, {
          locationId,
          userId
        });
        setLocations(locations.filter(loc => loc.id !== locationId));
      } catch (err) {
        console.error('Error deleting location:', err);
        alert('Error al eliminar la ubicación');
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="locations-modal-overlay">
      <div className="locations-modal-content">
        <button className="locations-modal-close" onClick={onClose}>&times;</button>
        <div className="locations-modal-header">
          <MapPin size={24} />
          <h2>Ubicaciones del Cliente: {clientName}</h2>
        </div>

        {loading && <div key="loading-container" style={{ width: "100%", height: "40vh", display: "flex", justifyContent: "center", alignContent: "center", flexWrap: "wrap" }}>
                <Circles
                  height="80"
                  width="80"
                  color="#000000"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>}
        {error && <p className="locations-error">{error}</p>}

        {!loading && !error && locations.length === 0 && (
          <p className="locations-empty">No hay ubicaciones registradas para este cliente.</p>
        )}

        {!loading && !error && locations.length > 0 && (
          <table className="locations-table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {locations.map((location) => (
                <tr key={location.id}>
                  <td>{location.nombre}</td>
                  <td className="actions-cell">
                    <button onClick={() => handleEdit(location)} className="edit-button">
                      <Edit size={18} />
                    </button>
                    <button onClick={() => handleDelete(location.id)} className="delete-button">
                      <Trash2 size={18} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {editingLocation && (
          <EditLocations 
            location={editingLocation}
            onClose={() => setEditingLocation(null)}
            onSave={(updatedLocation) => {
              setLocations(locations.map(loc => 
                loc.id === updatedLocation.id ? updatedLocation : loc
              ));
              setEditingLocation(null);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default LocationsModal;