import React, { useState, useEffect } from "react";
import GoBack from "../components/GoBack";
import axios from "axios";
import config from "../config";
import "../styles/MaterialRequest.css";
import { Truck, Plus, X } from "lucide-react";

const ArticuloSelect = ({ onSelect, value, userId }) => {
  const [articulos, setArticulos] = useState([]);
  const [error, setError] = useState(null);
  const environment = process.env.NODE_ENV || "development";

  useEffect(() => {
    const fetchArticulos = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        const response = await axios.get(
          `${config[environment].API_BASE_URL}get_articulos.php`,
          {
            params: { userId },
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.success && Array.isArray(response.data.data)) {
          const articulosActivos = response.data.data.filter(
            (art) => art.bEstado === true
          );
          setArticulos(articulosActivos);
          setError(null);
        } else {
          throw new Error("Formato de respuesta inválido");
        }
      } catch (err) {
        setError("Error al cargar los artículos. Por favor, inténtelo de nuevo.");
        setArticulos([]);
      }
    };

    if (userId) {
      fetchArticulos();
    }
  }, [userId, environment]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="articulo-select-container">
      <select
        value={value ? value.sArticuloCorto : ""}
        onChange={(e) => {
          const selectedArticulo = articulos.find(
            (a) => a.sArticuloCorto === e.target.value
          );
          onSelect(selectedArticulo);
        }}
        className="articulo-select"
      >
        <option value="">Seleccionar artículo</option>
        {articulos.map((articulo) => (
          <option key={articulo.idArticulo} value={articulo.sArticuloCorto}>
            {articulo.sArticuloCorto} - {articulo.sArticuloLargo}
          </option>
        ))}
      </select>
      {articulos.length === 0 && !error && (
        <div className="no-articulos">No hay artículos disponibles</div>
      )}
      {articulos.length > 0 && (
        <div className="articulos-count">
          Artículos disponibles: {articulos.length}
        </div>
      )}
    </div>
  );
};

const MaterialRequest = () => {
  const [selectedArticulo, setSelectedArticulo] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [uds, setQuantity] = useState("");
  const [addedArticles, setAddedArticles] = useState([]);
  const [message, setMessage] = useState("");
  const [clients, setClients] = useState([]);
  const [clientError, setClientError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  //const [currentPaquete, setCurrentPaquete] = useState(null);

  const environment = process.env.NODE_ENV || "development";
  const apiUrl = config[environment].API_BASE_URL;

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(parseInt(storedUserId));
    }
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      if (!userId) return;

      setLoading(true);
      try {
        const authToken = localStorage.getItem("authToken");
        const response = await axios.get(`${apiUrl}get_relations.php`, {
          params: {
            userId: userId,
            id: userId,
            tipo: "usuario",
          },
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (Array.isArray(response.data) && response.data.length > 0) {
          setClients(response.data);
          setClientError(null);
        } else {
          setClientError("No se encontraron clientes asociados");
        }
      } catch (err) {
        setClientError("Error al cargar los clientes. Por favor, inténtelo de nuevo.");
        setClients([]);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, [apiUrl, userId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedArticulo && uds) {
      if (parseInt(uds) > 10) {
        setMessage("No se pueden solicitar más de 10 unidades por artículo");
        return;
      }

      const existingArticleIndex = addedArticles.findIndex(
        (art) => art.idArticulo === selectedArticulo.idArticulo
      );

      if (existingArticleIndex !== -1) {
        const newTotal = addedArticles[existingArticleIndex].uds + parseInt(uds);
        if (newTotal > 10) {
          setMessage("El total de unidades no puede superar 10 por artículo");
          return;
        }
        const updatedArticles = [...addedArticles];
        updatedArticles[existingArticleIndex].uds = newTotal;
        setAddedArticles(updatedArticles);
      } else {
        setAddedArticles([
          ...addedArticles,
          { ...selectedArticulo, uds: parseInt(uds) },
        ]);
      }

      setSelectedArticulo(null);
      setQuantity("");
      setMessage("");
    } else {
      setMessage("Por favor, seleccione un artículo y cantidad");
    }
  };

  const handleSendRequest = async () => {
    if (!selectedClient) {
      setMessage("Por favor, seleccione un cliente");
      return;
    }
  
    if (!userId) {
      setMessage("Error: Usuario no identificado");
      return;
    }
  
    if (addedArticles.length === 0) {
      setMessage("Por favor, añada al menos un artículo");
      return;
    }
  
    const requestData = {
      userId: userId,
      idCliente: selectedClient.idCliente,
      movimientos: addedArticles.map((article) => ({
        idArticulo: article.idArticulo,
        iUnidades: article.uds,
      })),
    };
  
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}insert_paquete.php`,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.data.success) {
        setMessage("Solicitud enviada correctamente");
        setAddedArticles([]);
        setSelectedClient(null);
      } else {
        throw new Error(response.data.mensaje || "Error en la respuesta del servidor");
      }
    } catch (error) {
      console.error('Error completo:', error);
      if (error.response?.data?.mensaje) {
        setMessage(error.response.data.mensaje);
      } else if (error.message) {
        setMessage(error.message);
      } else {
        setMessage("Error al enviar la solicitud. Por favor, inténtelo de nuevo.");
      }
    } finally {
      setLoading(false);
    }
  };

  if (!userId) {
    return (
      <div className="material-request-container">
        <GoBack />
        <div className="error-message">
          Error: Usuario no identificado. Por favor, inicie sesión nuevamente.
        </div>
      </div>
    );
  }

  return (
    <div className="material-request-container">
      <GoBack />
      <div className="material-request-content">
        <h2>Solicitud de Material</h2>
        <div className="material-request-form">
        <div className="form-group">
            <label>Cliente:</label>
            {clientError ? (
              <div className="error-message">{clientError}</div>
            ) : (
              <select
                value={selectedClient ? selectedClient.idCliente : ""}
                onChange={(e) => {
                  const client = clients.find(
                    (c) => c.idCliente === parseInt(e.target.value)
                  );
                  setSelectedClient(client);
                }}
                className="client-select"
              >
                <option value="">Seleccionar cliente</option>
                {clients.map((client) => (
                  <option key={client.idCliente} value={client.idCliente}>
                    {client.sCliente}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
        <div className="material-request-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Artículo:</label>
              <ArticuloSelect
                onSelect={setSelectedArticulo}
                value={selectedArticulo}
                userId={userId}
              />
            </div>
            <div className="form-group" id="unityInput">
              <label>Unidades:</label>
              <input
                type="number"
                value={uds}
                onChange={(e) => setQuantity(e.target.value)}
                min="1"
                max="10"
                required
              />
            </div>
            <button type="submit" className="add-button" disabled={loading}>
              <Plus size={20} /> Añadir
            </button>
          </form>
        </div>

        <div className="added-articles">
          <h3>Artículos Añadidos</h3>
          {addedArticles.length > 0 ? (
            <>
              <ul>
                {addedArticles.map((article, index) => (
                  <li key={index}>
                    {article.sArticuloCorto} - {article.uds} unidades
                    <button
                      type="button"
                      onClick={() => {
                        const newArticles = addedArticles.filter(
                          (_, i) => i !== index
                        );
                        setAddedArticles(newArticles);
                      }}
                      className="remove-button"
                    >
                      <X size={16} />
                    </button>
                  </li>
                ))}
              </ul>

              {selectedClient && (
                <button
                  type="button"
                  onClick={handleSendRequest}
                  className="send-button"
                  disabled={loading}
                >
                  <Truck size={24} />
                  {loading ? "Enviando..." : `Enviar Solicitud para ${selectedClient.sCliente}`}
                </button>
              )}
            </>
          ) : (
            <p>No hay artículos añadidos</p>
          )}
        </div>

        {message && (
          <div className={`message ${message.includes("Error") ? "error" : "success"}`}>
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default MaterialRequest;