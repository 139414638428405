import UserStaff from '../components/UsersStaff';
import GoBack from '../components/GoBack';
import "../styles/staff.css";
import AddStaffButton from '../components/AddStaffButton';

const Staff = () => {
    return (
        <div key="main-staff-container" className='main-staff'>
            <GoBack key="go-back-component" />
            <AddStaffButton key="add-staff-button" />
            <UserStaff key="user-staff-component" />
        </div>
    );
};

export default Staff;