import React from 'react';
import { HashRouter, useLocation, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import NavBar from './components/Navbar';
import Header from './components/Header';
import AppRoutes from './AppRoutes';
import './App.css';
import { Circles } from 'react-loader-spinner';
import LocaleUpdater from './LocaleUpdater';
import { TranslationProvider } from './TranslationContext';
import './styles/theme.css';

function AppContent() {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth();

  const hideNavbarPaths = ['/login', '/Saas', '/data', '/register', '/terms', '/privacy'];
  const hideHeaderPaths = ['/', '/data'];

  const showNavBar = !hideNavbarPaths.includes(location.pathname);
  const showHeader = !hideHeaderPaths.includes(location.pathname);

  if (isLoading) {
    return (
      <div key="loading-container" style={{ width: "100%", height: "90vh", display: "flex", justifyContent: "center", alignContent: "center", flexWrap: "wrap" }}>
        <Circles
          height="80"
          width="80"
          color="#000000"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  if (!isAuthenticated && location.pathname !== '/login') {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <TranslationProvider key="translation-provider">
      <div key="app-container" className="App">
        {showNavBar && <NavBar key="navbar" />}
        {showHeader && <Header key="header" />}
        <main key="main-content" className="main-content">
          <AppRoutes />
        </main>
      </div>
    </TranslationProvider>
  );
}

function App() {
  return (
    <AuthProvider>
      <HashRouter>
        <LocaleUpdater key="locale-updater" />
        <AppContent key="app-content" />
      </HashRouter>
    </AuthProvider>
  );
}

export default App;