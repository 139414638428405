const config = {
  development: {
    API_BASE_URL: 'http://localhost/api/',
    PHP_LOGIN_URL: 'http://localhost/api/login.php',
    PHP_REGISTER_URL: 'http://localhost/api/register.php',
    PHP_GET_USER_PERMISSIONS_URL: 'http://localhost/api/get_user_permissions.php',
    PHP_VALIDATE_TOKEN_URL: 'http://localhost/api/validate_token.php',
    PHP_GET_STAFF_URL: 'http://localhost/api/get_staff.php',
    PHP_GET_CLIENTS_URL: 'http://localhost/api/get_client.php',
    PHP_UPDATE_ESTADO_URL: 'http://localhost/api/update_estado_usuario.php',
    PHP_GET_EDITED_PERMISSIONS_URL: 'http://localhost/api/get_edited_permissions.php',
    PHP_UPDATE_PERMISSIONS_URL: 'http://localhost/api/update_permissions.php',
    PHP_GET_ROUTES_URL: 'http://localhost/api/get_routes.php',
    PHP_GET_ARTICULOS_URL: 'http://localhost/api/get_articulos.php',
    PHP_GET_USERS_AND_CLIENTS_URL: 'http://localhost/api/get_schedule.php',
    PHP_GET_SUPPLIES_URL: 'http://localhost/api/get_item.php',
    PHP_UPDATE_MOVIMIENTOS_URL: 'http://localhost/api/update_movimientos.php',
    PHP_REGISTER_CLIENT_URL: 'http://localhost/api/register_client.php',
    PHP_UPDATE_ESTADO_CLIENTE_URL: 'http://localhost/api/update_estado_cliente.php',
    PHP_UPDATE_CLIENT_URL: 'http://localhost/api/update_client.php',
    PHP_DELETE_CLIENT_URL: 'http://localhost/api/delete_client.php',
    PHP_RESTORE_CLIENT_URL: 'http://localhost/api/restore_client.php',
    PHP_UPDATE_ESTADO_ARTICULO_URL: 'http://localhost/api/update_estado_articulo.php',
    PHP_UPDATE_MOVIMIENTO_ITEM_URL: 'http://localhost/api/update_movimiento_item.php',
    PHP_UPDATE_SUPPLIES_URL: 'http://localhost/api/update_estado_articulo.php',
    PHP_GET_MENU_ITEMS_URL: 'http://localhost/api/get_menu_items.php',
    PHP_UPDATE_USER_URL: 'http://localhost/api/update_user.php',
    PHP_GET_CATEGORIES_URL: 'http://localhost/api/get_categories.php',
  },
  production: {
    API_BASE_URL: 'https://worksuite.es/api/',
    PHP_LOGIN_URL: 'https://worksuite.es/api/login.php',
    PHP_REGISTER_URL: 'https://worksuite.es/api/register.php',
    PHP_GET_USER_PERMISSIONS_URL: 'https://worksuite.es/api/get_user_permissions.php',
    PHP_VALIDATE_TOKEN_URL: 'https://worksuite.es/api/validate_token.php',
    PHP_GET_STAFF_URL: 'https://worksuite.es/api/get_staff.php',
    PHP_GET_CLIENTS_URL: 'https://worksuite.es/api/get_client.php',
    PHP_UPDATE_ESTADO_URL: 'https://worksuite.es/api/update_status.php',
    PHP_GET_EDITED_PERMISSIONS_URL: 'https://worksuite.es/api/get_edited_permissions.php',
    PHP_UPDATE_PERMISSIONS_URL: 'https://worksuite.es/api/update_permissions.php',
    PHP_GET_ROUTES_URL: 'https://worksuite.es/api/get_routes.php',
    PHP_GET_ARTICULOS_URL: 'https://worksuite.es/api/get_articulos.php',
    PHP_GET_USERS_AND_CLIENTS_URL: 'https://worksuite.es/api/get_schedule.php',
    PHP_GET_SUPPLIES_URL: 'https://worksuite.es/api/get_item.php',
    PHP_UPDATE_MOVIMIENTOS_URL: 'https://worksuite.es/api/update_movimientos.php',
    PHP_REGISTER_CLIENT_URL: 'https://worksuite.es/api/register_client.php',
    PHP_UPDATE_ESTADO_CLIENTE_URL: 'https://worksuite.es/api/update_estado_cliente.php',
    PHP_UPDATE_CLIENT_URL: 'https://worksuite.es/api/update_client.php',
    PHP_DELETE_CLIENT_URL: 'https://worksuite.es/api/delete_client.php',
    PHP_RESTORE_CLIENT_URL: 'https://worksuite.es/api/restore_client.php',
    PHP_UPDATE_ESTADO_ARTICULO_URL: 'https://worksuite.es/api/update_estado_articulo.php',
    PHP_UPDATE_MOVIMIENTO_ITEM_URL: 'https://worksuite.es/api/update_movimiento_item.php',
    PHP_UPDATE_SUPPLIES_URL: 'https://worksuite.es/api/update_estado_articulo.php',
    PHP_GET_MENU_ITEMS_URL: 'https://worksuite.es/api/get_menu_items.php',
    PHP_UPDATE_USER_URL: 'https://worksuite.es/api/update_user.php',
    PHP_GET_CATEGORIES_URL: 'https://worksuite.es/api/get_categories.php',
  }
};

export default config;